import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../components/Loading';

export default class Waiting extends React.Component {
  render() {
    let image;

    if (this.props.useLogo) {
      image = <Loading message={this.props.message} />;
    } else {
      image = (
        <div className="be-spinner">
          <svg
            width="40px"
            height="40px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              fill="none"
              strokeWidth="4"
              strokeLinecap="round"
              cx="33"
              cy="33"
              r="30"
              className="circle"
            />
          </svg>
        </div>
      );
    }

    const active = this.props.isProcessing ? 'be-loading-active' : '';
    const opaque = this.props.useLogo ? 'be-loading-opaque' : '';

    return (
      <div
        className={`be-loading ${active} ${opaque}`}
        style={{
          minHeight: active ? '50px' : '',
          ...this.props.style,
        }}
      >
        {this.props.children}

        {this.props.isProcessing && image}

        {!this.props.useLogo && this.props.isProcessing && this.props.message && (
          <>
            <br />
            <div
              style={{
                top: '55%',
                margin: 'auto',
                position: 'absolute',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {this.props.message}
            </div>
          </>
        )}
      </div>
    );
  }
}

Waiting.propTypes = {
  isProcessing: PropTypes.bool.isRequired,
  useLogo: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
