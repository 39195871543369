import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Col extends Component {
    render() {
        const classes = [];
        const {
            xs,
            xsOffset,
            sm,
            smOffset,
            md,
            mdOffset,
            lg,
            lgOffset,
            refFunc,
            className,
            ...rest
        } = this.props;

        xs && classes.push(`col-xs-${xs}`);
        sm && classes.push(`col-sm-${sm}`);
        md && classes.push(`col-md-${md}`);
        lg && classes.push(`col-lg-${lg}`);

        xsOffset && classes.push(`col-xs-offset-${xsOffset}`);
        smOffset && classes.push(`col-sm-offset-${smOffset}`);
        mdOffset && classes.push(`col-md-offset-${mdOffset}`);
        lgOffset && classes.push(`col-lg-offset-${lgOffset}`);

        className && classes.push(className);

        return (
            <div ref={refFunc} className={classes.join(' ')} {...rest}>
                {this.props.children}
            </div>
        );
    }
}

Col.propTypes = {
    xs: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    sm: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    md: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    lg: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    xsOffset: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    smOffset: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    mdOffset: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    lgOffset: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
    refFunc: PropTypes.func,
};

export default Col;
