import React from 'react';
import PropTypes from 'prop-types';

export default class Button extends React.Component {
  render() {
    const {
      processing,
      size,
      type,
      block,
      submit,
      className,
      disabled,
      ...rest
    } = this.props;

    // Avoid the warning with unrecognized props in DOM.
    const i18nProps = ['t', 'tReady', 'i18nOptions', 'defaultNS', 'reportNS'];
    i18nProps.forEach((p) => delete rest[p]);
    // End.

    const classes = `btn btn-${type} btn-${size} ${block ? 'btn-block' : ''} ${
      className || ''
    }`;

    return (
      <button
        className={classes}
        type={submit ? 'submit' : 'button'}
        disabled={processing || disabled}
        {...rest}
      >
        {this.props.children}

        {processing && (
          <>
            &nbsp;
            <i className="fas fa-spinner fa-pulse fa-1x fa-fw" />
          </>
        )}
      </button>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  processing: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  type: PropTypes.oneOf([
    'success',
    'primary',
    'info',
    'warning',
    'danger',
    'default',
    'link',
  ]),
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  submit: PropTypes.bool,
};

Button.defaultProps = {
  type: 'primary',
  processing: false,
  size: 'md',
  block: false,
};
