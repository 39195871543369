import React from "react"
import PropTypes from "prop-types"
import HtmlUtils from "../htmlutils";
import { withNamespaces } from "react-i18next";

class PaginationWithClick extends React.Component {
    constructor(props) {
        super(props);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.onLast = this.onLast.bind(this);
        this.onFirst = this.onFirst.bind(this);
        this.onLinkClick = this.onLinkClick.bind(this);
        this.paginationRef = React.createRef();
    }

    onNext() {
        this.props.handleClick(this.props.pagination.links.next);
        HtmlUtils.scrollFirstScrollableToTop(this.paginationRef.current);
    }

    onPrevious() {
        this.props.handleClick(this.props.pagination.links.previous);
        HtmlUtils.scrollFirstScrollableToTop(this.paginationRef.current);
    }

    onLast() {
        this.props.handleClick(this.props.pagination.links.last);
        HtmlUtils.scrollFirstScrollableToTop(this.paginationRef.current);
    }

    onFirst() {
        this.props.handleClick(this.props.pagination.links.first);
        HtmlUtils.scrollFirstScrollableToTop(this.paginationRef.current);
    }

    onLinkClick(url) {
        this.props.handleClick(url);
        HtmlUtils.scrollFirstScrollableToTop(this.paginationRef.current);
    }

    render() {
        const { i18n, pagination } = this.props;

        return (
            <div ref={this.paginationRef}>
                {pagination.totalPages > 1 &&
                    <nav style={{textAlign: 'center'}}>
                        <ul className="pagination">
                            {pagination.links.first && (
                                <li>
                                    <a onClick={this.onFirst}
                                       aria-label={i18n.t('Início')}
                                       title={i18n.t('Início')}
                                       role="button"
                                    >
                                        <span className="mdi mdi-chevron-left"/>
                                        <span className="mdi mdi-chevron-left"/>
                                    </a>
                                </li>
                            )}

                            {pagination.pageNumber > 1 && (
                                <li>
                                    <a onClick={this.onPrevious}
                                       title={i18n.t('Anterior')}
                                       aria-label={i18n.t('Anterior')}
                                       role="button"
                                    >
                                        <span className="mdi mdi-chevron-left"> </span>
                                    </a>
                                </li>
                            )}

                            {pagination.links.middleLinks && (
                                pagination.links.middleLinks.map(item => (
                                    <li
                                        key={item.pageNumber}
                                        className={item.active ? 'active' : ''}
                                    >
                                        <a
                                            onClick={() => this.onLinkClick(item.link)}
                                            role="button"
                                            title={i18n.t('Página') + ' ' + item.pageNumber}
                                            aria-label={i18n.t('Página') + ' ' + item.pageNumber}
                                        >
                                            {item.pageNumber}
                                        </a>
                                    </li>
                                ))
                            )}

                            {pagination.links.next && (
                                <li>
                                    <a onClick={this.onNext}
                                       title={i18n.t('Próximo')}
                                       aria-label={i18n.t('Próximo')}
                                       role="button"
                                    >
                                        <span className="mdi mdi-chevron-right"> </span>
                                    </a>
                                </li>
                            )}

                            {pagination.links.last && (
                                <li>
                                    <a onClick={this.onLast}
                                       aria-label={i18n.t('Último')}
                                       title={i18n.t('Último')}
                                       role="button"
                                    >
                                        <span className="mdi mdi-chevron-right"/>
                                        <span className="mdi mdi-chevron-right"/>
                                    </a>
                                </li>
                            )}

                            <li>
                                <a style={{
                                    backgroundColor: 'unset',
                                    border: 'unset',
                                }}>
                                    <i className="text-muted text-center">
                                        {pagination.count} {i18n.t('resultados')}
                                    </i>
                                </a>
                            </li>
                        </ul>
                    </nav>}
            </div>
        )
    }
}

PaginationWithClick.propTypes = {
    /**
     * A function witch receives the URL clicked in the pagination item.
     */
    handleClick: PropTypes.func.isRequired,
    pagination: PropTypes.object.isRequired,
};

PaginationWithClick.defaultProps = {
    handleClick: function(url) {console.log(url)},
    pagination: {},
};

export default withNamespaces()(PaginationWithClick);