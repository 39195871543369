import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Row extends Component {
    render() {
        const {refFunc, style, ...rest} = this.props;
        return (
            <div className="row" ref={refFunc} style={{...style}} {...rest}>
                {this.props.children}
            </div>
        );
    }
}

Row.propTypes = {
    refFunc: PropTypes.func,
};

export default Row;
